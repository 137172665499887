import { type FC, Fragment } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@mui/material'
import { isEmpty, isNull, isUndefined } from 'lodash'

import { NoUserPhoto, NoImage } from 'assets'

import type { TImageProps } from './types'
import styles from './ImageComponent.module.scss'

const ImageComponent: FC<TImageProps> = ({
  src,
  alt,
  width = '48px',
  height = '48px',
  type = 'image',
  className = '',
  loading = 'eager',
  isLoading = false,
  containerClass = '',
}) => {
  const isUndefOrSrc = isUndefined(src) || isNull(src) || isEmpty(src) ? undefined : src

  return (
    <div className={classNames(styles.container, containerClass)}>
      {isLoading ? (
        <Skeleton variant='circular' width={width} height={height} className='skeleton__light' />
      ) : (
        <Fragment>
          {isUndefined(isUndefOrSrc) ? (
            type === 'image' ? (
              <NoImage
                width={width}
                height={height}
                key={isUndefOrSrc}
                className={className}
                style={{ display: 'block' }}
              />
            ) : (
              <NoUserPhoto
                width={width}
                height={height}
                key={isUndefOrSrc}
                className={className}
                style={{ display: 'block' }}
              />
            )
          ) : (
            <img
              width={width}
              height={height}
              loading={loading}
              key={isUndefOrSrc}
              src={isUndefOrSrc}
              className={className}
              alt={alt || 'image_alt'}
              referrerPolicy='no-referrer'
            />
          )}
        </Fragment>
      )}
    </div>
  )
}

export default ImageComponent

import { createSlice } from '@reduxjs/toolkit'

import { setCookie } from 'libraries/cookie'
import { COOKIE_EXPIRATION_TIME } from 'utils'

import { clearProfileData, getProfileData, updateUserInformation } from './actions'
import type { IUserState } from './types'

const initialState: IUserState = {
  data: null,
  error: null,
  loading: false,

  profile: {
    data: null,
    error: null,
    loading: false,
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser: (state, action) => {
      state.data = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProfileData.pending, state => {
        state.profile.loading = true
        state.profile.error = null
      })
      .addCase(getProfileData.rejected, (state, action) => {
        state.profile.loading = false
        state.profile.error = action.payload as null
      })
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.profile.loading = false
        state.profile.error = null
        state.profile.data = action.payload
      })
      .addCase(clearProfileData.fulfilled, (state, action) => {
        state.profile.loading = false
        state.profile.error = null
        state.profile.data = action.payload
      })
      .addCase(updateUserInformation.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(updateUserInformation.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(updateUserInformation.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.data = { ...state.data, ...action.payload }
        state.profile.data = { ...state.profile.data, ...action.payload }
        setCookie('user', JSON.stringify({ ...state.data }), COOKIE_EXPIRATION_TIME)
      })
  },
})

export const { getUser } = userSlice.actions

const userSliceReducer = userSlice.reducer

export default userSliceReducer

import type { FC } from 'react'
import classNames from 'classnames'

import type { TTradingBadge } from './types'
import styles from './TradingBadge.module.scss'

const TradingBadge: FC<TTradingBadge> = ({ variant, color }) => {
  const getBadge = () => {
    switch (variant) {
      case 'badge':
        return <span className={classNames(styles.badge, { [styles.badge__sell]: color === 'sell' })}>{color}</span>
      case 'dot':
        return <span className={classNames(styles.dot, { [styles.dot__sell]: color === 'sell' })} />
      case 'dotWithText':
        return (
          <div className={styles.dot__container}>
            <span className={classNames(styles.dot, { [styles.dot__sell]: color === 'sell' })} />

            <span className={classNames(styles.dot__text, { [styles.dot__text__sell]: color === 'sell' })}>
              {color}
            </span>
          </div>
        )
      default:
        return null
    }
  }

  return getBadge()
}

export default TradingBadge

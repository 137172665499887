const firstNonZeroIndex = (num: number): number => {
  const numStr = num.toFixed(20).replace('.', '0')
  let firstNonZeroIndex = 0

  for (let i = 0; i < numStr.length; i++) {
    if (numStr[i] !== '0') {
      firstNonZeroIndex = i

      break
    }
  }

  return firstNonZeroIndex
}

export const thousandthDigit = (num: number | string | null | undefined): string => {
  const numStr = Number(num).toFixed(20)
  let fixedNum = Number(num).toFixed(2)

  if (Number(num) < 1 && numStr[2] === '0' && numStr[3] === '0') {
    fixedNum = Number(num).toFixed(firstNonZeroIndex(Number(num)))
  }

  const [integerPart, decimalPart] = fixedNum.split('.')

  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (parseInt(decimalPart) === 0 || isNaN(parseInt(decimalPart))) {
    return integerWithCommas
  } else {
    return `${integerWithCommas}.${decimalPart}`
  }
}

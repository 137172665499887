import { lazy } from 'react'

import { ERoutePaths, type TRoutePageType } from './types'

const HomeNew = lazy(() => import('pages/HomeNew'))
const News = lazy(() => import('pages/News'))
const Error = lazy(() => import('pages/Error'))
const NewsItem = lazy(() => import('pages/NewsItem'))
const Settings = lazy(() => import('pages/Settings'))
const MyAccount = lazy(() => import('pages/MyAccount'))
const Portfolio = lazy(() => import('pages/Portfolio'))
const ComingSoon = lazy(() => import('pages/ComingSoon'))
const PortfolioToken = lazy(() => import('pages/PortfolioToken'))

const routesList: TRoutePageType[] = [
  {
    element: HomeNew,
    path: ERoutePaths.Home,
    title: 'Amatuni Home',
  },
  {
    element: Portfolio,
    isPrivate: true,
    path: ERoutePaths.Portfolio,
    title: 'Portfolio',
  },
  {
    element: PortfolioToken,
    path: ERoutePaths.PortfolioToken,
    title: 'PortfolioToken',
  },
  {
    title: 'ComingSoon',
    element: ComingSoon,
    path: ERoutePaths.ComingSoon,
  },
  {
    title: 'News',
    element: News,
    isPrivate: true,
    path: ERoutePaths.News,
  },
  {
    title: 'Blog',
    element: News,
    isPrivate: true,
    path: ERoutePaths.Blog,
  },
  {
    title: 'NewsItem',
    element: NewsItem,
    isPrivate: true,
    path: ERoutePaths.BlogItem,
  },
  {
    title: 'BlogItem',
    element: NewsItem,
    isPrivate: true,
    path: ERoutePaths.NewsItem,
  },

  {
    title: 'News',
    element: News,
    path: '/news-new',
  },
  {
    title: 'Blog',
    element: News,
    path: '/blog-new',
  },
  {
    title: 'NewsItem',
    element: NewsItem,
    path: '/news-new/:id',
  },
  {
    title: 'BlogItem',
    element: NewsItem,
    path: '/blog-new/:id',
  },

  // @TODO: Private routes
  {
    isPrivate: true,
    element: MyAccount,
    title: 'My account',
    path: ERoutePaths.MyAccount,
  },
  {
    isPrivate: true,
    element: Settings,
    title: 'Settings',
    path: ERoutePaths.Settings,
  },
  {
    element: Error,
    path: ERoutePaths.Error,
    title: 'Error Page',
  },
]

export default routesList

import type { FC } from 'react'

import { thousandthDigit } from 'utils'
import { StatisticsCount, Text, CoinProgressBar } from 'components'

import type { TCoinStats } from './types'
import styles from './CoinStats.module.scss'

const CoinStats: FC<TCoinStats> = ({ price, volume, totalSupply, maxSupply, circSupply }) => {
  const symbol = '$'

  return (
    <div className={styles.wrapper}>
      <Text text='Insights' className={styles.wrapper__heading} />

      <div className={styles.wrapper__table}>
        <div className={styles.wrapper__table__container}>
          <div className={styles.wrapper__table__fluctuation}>
            <div className={styles.wrapper__market}>
              <Text text='MarketCap' RText=':' className={styles.wrapper__market__name} />

              <div className={styles.wrapper__market__value}>
                <StatisticsCount percent={3242342} />

                <p className={styles.wrapper__market__price}>{price ? `$${thousandthDigit(price)} ` : '--'}</p>
              </div>
            </div>

            <div className={styles.wrapper__divider} />

            <div className={styles.wrapper__market}>
              <Text text='Volume(24h)' RText=':' className={styles.wrapper__market__name} />

              <div className={styles.wrapper__market__value}>
                <StatisticsCount percent={23423423} />

                <p className={styles.wrapper__market__price}>{volume ? `$${volume}` : '━'}</p>
              </div>
            </div>
          </div>
          <div className={styles.wrapper__table__divider} />

          <div className={styles.wrapper__table__fluctuation}>
            <div className={styles.wrapper__market}>
              <Text text='TotalSupply' RText=':' className={styles.wrapper__market__name} />

              <div className={styles.wrapper__market__value}>
                <p className={styles.wrapper__market__price}>
                  {totalSupply ? `${thousandthDigit(totalSupply)} ${symbol}` : '--'}
                </p>
              </div>
            </div>
            <div className={styles.wrapper__divider} />

            <div className={styles.wrapper__market}>
              <Text text='MaxSupply' RText=':' className={styles.wrapper__market__name} />

              <div className={styles.wrapper__market__value}>
                <p className={styles.wrapper__market__price}>
                  {maxSupply ? `${thousandthDigit(maxSupply)} ${symbol}` : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.wrapper__divider} />

        <div className={styles.wrapper__table__fluctuation}>
          <div className={styles.wrapper__market}>
            <Text text='CirculatingSupply' RText=':' className={styles.wrapper__market__name} />

            <div className={styles.wrapper__market__value}>
              <p className={styles.wrapper__market__price}>
                {circSupply ? `${thousandthDigit(circSupply)} ${symbol}` : '--'}
              </p>
            </div>
          </div>

          <CoinProgressBar currentValue={50} maxValue={100} />
        </div>
      </div>
    </div>
  )
}

export default CoinStats

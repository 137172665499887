import { type FC, Suspense, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { HelmetLayout, RouteLoader, PrivateRoute } from 'components'

import routesList from './routes'
import { useAuthentication } from 'hooks'

export const RoutesWrapper: FC = () => {
  const { isAuthenticated } = useAuthentication()

  const renderRoutes = useMemo(
    () =>
      routesList?.map(({ element: Element, path, title, isPrivate }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateRoute isPrivate={isPrivate} isAuthenticated={isAuthenticated}>
              <Suspense fallback={<RouteLoader />}>
                <HelmetLayout key={title} title={title}>
                  <Element />
                </HelmetLayout>
              </Suspense>
            </PrivateRoute>
          }
        />
      )),

    [isAuthenticated]
  )

  return <Routes>{renderRoutes}</Routes>
}

import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'
import { GrowthIcon } from 'assets'

import type { TStatisticsCountProps } from './types'
import styles from './StatisticsCount.module.scss'
import { Skeleton } from '@mui/material'

const StatisticsCount: FC<TStatisticsCountProps> = ({
  percent,
  text = '',
  leftText = '',
  className = '',
}) => {
  const incrementOrDecrement: boolean = String(percent).includes('-')

  return (
    <div
      className={classNames(styles.item, {
        [className]: className,
      })}
    >
      {(text || leftText) && <Text text={text} LText={leftText} RText=' %' className={styles.item_time} />}

      <GrowthIcon
        className={classNames({
          [styles.item_increment]: !incrementOrDecrement,
          [styles.item_decrement]: incrementOrDecrement,
        })}
      />

      <p
        className={classNames(styles.item_percent, {
          [styles.item_percent_increment]: !incrementOrDecrement,
          [styles.item_percent_decrement]: incrementOrDecrement,
        })}
      >
        {percent ? (
          `${parseFloat(String(percent)).toFixed(2)}%`
        ) : (
          <Skeleton className='skeleton__dark' width={20} />
        )}
      </p>
    </div>
  )
}

export default StatisticsCount

import type { RootState } from 'types'

const coinsList = (state: RootState) => state.global.coins.data
const isSidebarOpen = (state: RootState) => state.global.isSideBarOpen
const authModalType = (state: RootState) => state.global.authModalType
const currentFaculty = (state: RootState) => state.global.currentFaculty
const isLoginModalOpen = (state: RootState) => state.global.isLoginModalOpen
const isRegistrationModalOpen = (state: RootState) => state.global.isRegistrationModalOpen

export const GlobalSelectors = {
  coinsList,
  authModalType,
  isSidebarOpen,
  currentFaculty,
  isLoginModalOpen,
  isRegistrationModalOpen,
}

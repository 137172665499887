import { format, parseISO } from 'date-fns'
import { useNavigate, Link } from 'react-router-dom'

import styles from './NewsCard.module.scss'

import { ArrowRightIcon } from 'assets'
import { renderCoinByIcon } from 'utils'

import newsIcon from 'assets/images/news/news.png'

const NewsCard = ({ data }: any) => {
  const navigate = useNavigate()

  const { id, name, image, description, createdAt, coin } = data
  const isNewsPage = location.pathname.split('/')[1] === 'news-new'
  const formattedDate = format(parseISO(createdAt), 'MM/dd/yyyy')

  const cleanTextFromHTML = (text: string) => {
    const textWithoutHTML = text.replace(/<\/?[^>]+(>|$)/g, '')

    const firstSentenceEnd = textWithoutHTML.search(/[.!?]/)

    return firstSentenceEnd !== -1 ? textWithoutHTML.slice(0, firstSentenceEnd + 1) : textWithoutHTML
  }

  return (
    <div
      role='button'
      className={styles.card}
      onClick={() => {
        if (isNewsPage) {
          navigate(`/news-new/${id}`)
        } else {
          navigate(`/blog-new/${id}`)
        }
      }}
    >
      <div className={styles.card__image}>
        <img src={image || newsIcon} alt='news' />
      </div>
      <div className={styles.card__content}>
        <div>
          <p className={styles.card__content_title}>{name}</p>
          <p className={styles.card__content_subtitle}>{cleanTextFromHTML(description)}</p>
          <div className={styles.card__content_category}>
            <img src={renderCoinByIcon(coin.name)} alt={coin.name} />
          </div>
        </div>
        <div className={styles.card__content_date}>
          <p>{formattedDate}</p>
          <Link to='/news-new'>
            Read more <ArrowRightIcon />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NewsCard

import {
  L2Icon,
  MarketIcon,
  ProjectIcon,
  BitcoinIcon,
  EtheriumIcon,
  MemecoinIcon,
  RegulationsIcon,
  L2Banner,
  EtherBanner,
  MarketBanner,
  BitcoinBanner,
  ProjectBanner,
  MemecoinBanner,
  RegulationBanner,
} from 'assets'

export const coinNames = ['ethereum', 'bitcoin', 'tether', 'solana', 'dogecoin', 'cardano', 'tron']

export const renderCoinByIcon = (coin: string) => {
  switch (coin?.toLocaleLowerCase()) {
    case 'ethereum':
      return EtherBanner
    case 'bitcoin':
      return BitcoinBanner
    case 'tether':
      return MarketBanner
    case 'solana':
      return MemecoinBanner
    case 'dogecoin':
      return RegulationBanner
    case 'cardano':
      return ProjectBanner
    case 'tron':
      return L2Banner
    default:
      return BitcoinBanner
  }
}

export const renderIconByCoinName = (coin: string) => {
  switch (coin?.toLocaleLowerCase()) {
    case 'ethereum':
      return EtheriumIcon
    case 'bitcoin':
      return BitcoinIcon
    case 'tether':
      return MarketIcon
    case 'solana':
      return MemecoinIcon
    case 'dogecoin':
      return RegulationsIcon
    case 'cardano':
      return ProjectIcon
    case 'tron':
      return L2Icon
    default:
      return BitcoinIcon
  }
}

export const renderCoinName = (coin: string) => {
  switch (coin?.toLocaleLowerCase()) {
    case 'ethereum':
      return 'Ethereum'
    case 'bitcoin':
      return 'Bitcoin'
    case 'tether':
      return 'Market'
    case 'solana':
      return 'Memecoin'
    case 'dogecoin':
      return 'Regulation'
    case 'cardano':
      return 'Project'
    case 'tron':
      return 'L2'
    default:
      return null
  }
}

export const modifayNewsCoinsList = (coins: any) => {
  const filteredCoinsList = coins?.filter((item: any) => coinNames.includes(item.coinId))

  const modifyCoins = filteredCoinsList.map((item: any) => {
    const coinName = item.coinId

    return {
      id: item.id,
      coinId: item.coinId,
      image: item.image,
      name: renderCoinName(coinName),
    }
  })

  return modifyCoins
}

import type { FC } from 'react'

import { CalendarIcon } from 'assets'
import { DividerWithText } from 'components'

import type { TPortfolioDates } from './types'
import PortfolioDatesItem from './PortfolioDatesItem'
import styles from './PortfolioDates.module.scss'
import { formatDateToText } from 'utils'

const PortfolioDates: FC<TPortfolioDates> = ({ data, date }) => {
  const renderItems = data.map(({ id, percent, time, trade }) => (
    <PortfolioDatesItem key={id} id={id} percent={percent} time={time} trade={trade} />
  ))

  return (
    <div className={styles.wrapper}>
      <DividerWithText
        NearTextIcon={CalendarIcon}
        text={formatDateToText(date)}
        textClassName={styles.wrapper__title}
        dividerClassName={styles.wrapper__divider}
      />

      {renderItems}
    </div>
  )
}

export default PortfolioDates

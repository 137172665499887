import type { FC } from 'react'
import classNames from 'classnames'

import { useResponsive } from 'hooks'
import { EButtonVariants, ESizes } from 'types'
import { TelegramIcon, arsenAvatar } from 'assets'
import { Button, SocialLinks, Text } from 'components'
import type { THome } from 'components/molecules/types'

import { courseSocialLinks } from './utils'
import styles from './Introduction.module.scss'

const Introduction: FC<THome> = ({ className }) => {
  const { isMobile } = useResponsive()

  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.wrapper__left}>
        <div className={styles.wrapper__left__container}>
          <img src={arsenAvatar} className={styles.wrapper__left__container__inner} width='100%' height='100%' />
        </div>
      </div>

      <div className={styles.wrapper__right}>
        <div className={styles.wrapper__right__introduce}>
          <div className={styles.wrapper__right__introduce__inner}>
            {/* <p className={styles.wrapper__right__introduce__label}>👋 Hello everyone</p> */}
            <Text emptyTag text='Меня зовут ' />

            <Text tagName='span' text='Арсен,' className={styles.wrapper__right__introduce__name} />

            <br />

            <Text tagName='span' className={styles.wrapper__right__introduce__tell} text=' ...и здесь говорят ' />

            {isMobile && <br />}

            <Text tagName='span' text='правду!' className={styles.wrapper__right__introduce__true} />
          </div>
        </div>

        <div className={styles.wrapper__right__container}>
          <div className={styles.wrapper__right__social}>
            <a href='https://t.me/arsenamatuni' target='_blank'>
              <Button
                className={styles.wrapper__button}
                size={ESizes.Large}
                variant={EButtonVariants.Primary}
                RightIcon={TelegramIcon}
              >
                Join
              </Button>
            </a>

            <a href='https://youtu.be/crMRVyziAS4?feature=shared' target='_blank'>
              <Button className={styles.wrapper__button} size={ESizes.Large} variant={EButtonVariants.Quaternary}>
                AboutMe
              </Button>
            </a>
          </div>

          <SocialLinks
            links={courseSocialLinks}
            className={styles.wrapper__media}
            containerClassName={styles.wrapper__media__container}
          />
        </div>
      </div>
    </div>
  )
}

export default Introduction

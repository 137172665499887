import { useState, useEffect, type FC } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addMonths,
  subMonths,
  format,
  addDays,
  isSameMonth,
  isSameDay,
  isBefore,
  isAfter,
} from 'date-fns'

import { EButtonVariants, ESizes } from 'types'
import { ArrowLeftIcon, ArrowRightIcon } from 'assets'
import { Button, Text, TradingBadge } from 'components'

import type { TCalendarProps } from './types'
import styles from './Calendar.module.scss'

const Calendar: FC<TCalendarProps> = ({ selectedDates, onCellClick }) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [canNavigatePrev, setCanNavigatePrev] = useState(false)
  const [canNavigateNext, setCanNavigateNext] = useState(false)

  const renderHeader = () => {
    const dateFormat = 'MMMM yyyy'

    return (
      <div className={styles.header}>
        <Text text='TokenTimeline' className={styles.header__title} />

        <div className={styles.header__container}>
          <div className={styles.header__container__buy_sell}>
            <TradingBadge color='sell' variant='dotWithText' />
            <TradingBadge color='buy' variant='dotWithText' />
          </div>

          <div className={styles.header__container__date}>
            <Button
              className={styles.header__button}
              LeftIcon={ArrowLeftIcon}
              onClick={() => canNavigatePrev && setCurrentDate(subMonths(currentDate, 1))}
              size={ESizes.Large}
              disabled={!canNavigatePrev}
              variant={EButtonVariants.Quaternary}
            />

            <span className={styles.header__container__date__text}>{format(currentDate, dateFormat)}</span>

            <Button
              className={styles.header__button}
              LeftIcon={ArrowRightIcon}
              onClick={() => canNavigateNext && setCurrentDate(addMonths(currentDate, 1))}
              size={ESizes.Large}
              disabled={!canNavigateNext}
              variant={EButtonVariants.Quaternary}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderDays = () => {
    const days = []
    const dateFormat = 'eeee'
    const startDate = startOfWeek(currentDate, { weekStartsOn: 1 })

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={classNames(styles.col, styles.col_center)} key={i}>
          <Text emptyTag text={`${format(addDays(startDate, i), dateFormat)}`} />
        </div>
      )
    }

    return <div className={classNames(styles.days, styles.row)}>{days}</div>
  }

  const renderCells = () => {
    const monthStart = startOfMonth(currentDate)
    const monthEnd = endOfMonth(monthStart)
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 })
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 })

    const rows = []
    let days = []
    let day = startDate
    let formattedDate = ''

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, 'd')
        const cloneDay = day
        const isSelected = selectedDates.some(d => isSameDay(d, day))
        days.push(
          <div
            role='button'
            onClick={isSelected ? () => onCellClick?.(cloneDay) : noop}
            className={classNames(styles.col, styles.cell, {
              [styles.cell__disabled]: !isSameMonth(day, monthStart),
              [styles.cell__selected]: isSelected,
            })}
            key={day.toString()}
          >
            {isSelected && (
              <div className={styles.cell__container}>
                <span className={styles.cell__text}>+11.5</span>
                <div className={styles.cell__buy_sell}>
                  <TradingBadge color='sell' variant='dot' />
                  <TradingBadge color='buy' variant='dot' />
                </div>
              </div>
            )}
            <span className={styles.number}>{formattedDate}</span>
          </div>
        )
        day = addDays(day, 1)
      }
      rows.push(
        <div className={styles.row} key={day.toString()}>
          {days}
        </div>
      )
      days = []
    }

    return <div className={styles.body}>{rows}</div>
  }

  useEffect(() => {
    const monthStart = startOfMonth(currentDate)
    const monthEnd = endOfMonth(currentDate)

    setCanNavigatePrev(selectedDates.some(date => isBefore(date, monthStart)))
    setCanNavigateNext(selectedDates.some(date => isAfter(date, monthEnd)))
  }, [currentDate, selectedDates])

  return (
    <div className={styles.wrapper}>
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  )
}

export default Calendar

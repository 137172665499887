import { useState, type FC, useEffect } from 'react'
import classNames from 'classnames'

import type { TCoinProgressBar } from './types'
import styles from './CoinProgressBar.module.scss'

const CoinProgressBar: FC<TCoinProgressBar> = ({ currentValue, maxValue, color = 'blue' }) => {
  const [width, setWidth] = useState<number>(0)

  const countPercent = (maxValue: number, currentValue: number) => {
    return (currentValue / maxValue) * 100
  }

  const progress = countPercent(maxValue!, currentValue!)

  useEffect(() => {
    setWidth(progress)
  }, [progress])

  const wrapperClasses = classNames(styles.wrapper, {
    [styles.wrapper_blue]: color === 'blue',
    [styles.wrapper_green]: color === 'green',
  })

  const wrapperInnerClasses = classNames(styles.wrapper__inner, {
    [styles.wrapper__inner_blue]: color === 'blue',
    [styles.wrapper__inner_green]: color === 'green',
  })

  return (
    <div className={wrapperClasses}>
      <div className={wrapperInnerClasses} style={{ width: `${width}%` }} />
    </div>
  )
}

export default CoinProgressBar

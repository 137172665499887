import { Text } from 'components/atom'
import styles from './DiscoverAcademy.module.scss'

import { T1Icon, T2Icon, T3Icon, T4Icon, T5Icon, T6Icon, AmatuniIcon } from 'assets'

const EcosystemList = [
  {
    id: '1',
    icon: T1Icon,
    text: 'Binance Liquidation ZS',
    link: 'https://t.me/LiquidationBZS',
  },
  {
    id: '2',
    icon: T2Icon,
    text: 'Alert ZS',
    link: 'https://t.me/alertZS',
  },
  {
    id: '3',
    icon: T3Icon,
    text: 'L...',
    link: 'https://t.me/Learnzs_bot',
  },
  {
    id: '4',
    icon: T4Icon,
    text: 'L...',
    link: 'https://t.me/CryptoSlovarZS_bot',
  },
  {
    id: '5',
    icon: T5Icon,
    text: 'L...',
    link: 'https://t.me/CryptoIQZS_bot',
  },
  {
    id: '6',
    icon: T6Icon,
    text: 'L...',
    link: 'https://t.me/ZSRanking_Bot',
  },
]

const DiscoverAcademy = () => {
  const renderEcosystemList = EcosystemList.map(({ id, icon: Icon, text, link }) => (
    <a href={link} target='__blanck' key={id} className={styles.wrapper__content_item}>
      <Icon />
      <div>
        <p>{text}</p>
      </div>
    </a>
  ))

  return (
    <div className={styles.wrapper}>
      <Text tagName='h3' text='Экосистема' className={styles.title} />
      <div className={styles.wrapper__content}>{renderEcosystemList}</div>
      <AmatuniIcon className={styles.icon} />
    </div>
  )
}

export default DiscoverAcademy

import { useState } from 'react'

import { Button } from 'components'
import {
  okxIcon,
  ntxIcon,
  gateIcon,
  mexcIcon,
  bybitIcon,
  kucoinIcon,
  safepalIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'assets'
import { EButtonVariants, ESizes } from 'types'

import styles from './GetPrivate.module.scss'

const partnersList = [
  {
    id: 1,
    icon: bybitIcon,
    text: 'Bybit',
    link: 'https://partner.bybit.com/b/amatuni',
  },
  {
    id: 2,
    icon: safepalIcon,
    text: 'Safepal',
    link: 'https://fas.st/t/sRz9SEsH',
  },
  {
    id: 3,
    icon: gateIcon,
    text: 'Gate.io',
    link: 'https://www.gate.io/ref/VLFHUGSLCQ?ref_type=102',
  },
  {
    id: 4,
    icon: ntxIcon,
    text: 'HTX',
    link: 'https://www.htx.com/invite/ru-ru/1f?invite_code=e8kk9223',
  },
  {
    id: 6,
    icon: okxIcon,
    text: 'OKX',
    link: 'https://www.okx.com/join/AMATUNI',
  },
  {
    id: 5,
    icon: kucoinIcon,
    text: 'Kucoin',
    link: 'https://www.kucoin.com/r/af/QBAZ8U2W',
  },
  {
    id: 7,
    icon: mexcIcon,
    text: 'Mexc',
    link: 'https://www.mexc.com/ru-RU/login?inviteCode=mexc-Amatuni',
  },
]

const GetPrivate = () => {
  const [activeIndex, setactiveIndex] = useState(0)

  const renderPartners = partnersList.map(({ id, icon, text, link }) => (
    <a href={link} target='__blanck' key={id} className={styles.wrapper__cards_item}>
      <img src={icon} alt={text} />
      <p>{text}</p>
    </a>
  ))

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__top}>
        <p className={styles.wrapper__title}>Партнеры</p>
        <div className={styles.wrapper__top_controller}>
          <Button
            size={ESizes.Small}
            LeftIcon={ArrowLeftIcon}
            disabled={!activeIndex}
            onClick={() => {
              setactiveIndex(0)
            }}
            variant={EButtonVariants.Secondary}
            className={styles.wrapper__button}
          />
          <Button
            size={ESizes.Small}
            LeftIcon={ArrowRightIcon}
            onClick={() => {
              setactiveIndex(1)
            }}
            disabled={!!activeIndex}
            variant={EButtonVariants.Secondary}
            className={styles.wrapper__button}
          />
        </div>
      </div>
      <div className={styles.wrapper__cards}>
        <div style={{ transform: `translateX(-${activeIndex * 100}%)` }} className={styles.wrapper__cards_content}>
          {renderPartners}
        </div>
      </div>
    </div>
  )
}

export default GetPrivate

import type { FC } from 'react'

import { TimeIcon } from 'assets'
import { TradingBadge, Text } from 'components'

import type { TPortfolioDatesItem } from '../PortfolioDates/types'
import styles from './/DayInfoModal.module.scss'

const DayInfoItem: FC<TPortfolioDatesItem> = ({ time, percent, trade }) => (
  <div className={styles.wrapper__container}>
    <div className={styles.wrapper__container__percent}>
      <TradingBadge color={trade} variant='badge' />

      <div className={styles.wrapper__container__time}>
        <TimeIcon />

        <Text text='at' RText={` ${time}`} />
      </div>
    </div>

    <p className={styles.wrapper__container__percent}>{percent}%</p>
  </div>
)

export default DayInfoItem

import { Fragment, type FC } from 'react'
import { format } from 'date-fns'

import { Modal } from 'components'
import { formatDateToText } from 'utils'

import DayInfoItem from './DayInfoItem'
import type { IDayInfoModal } from './types'
import styles from './DayInfoModal.module.scss'

const DayInfoModal: FC<IDayInfoModal> = ({ data, date, onClose, open }) => {
  const renderItems = data?.map(({ id, percent, time, trade }, index) => (
    <Fragment key={id}>
      <DayInfoItem id={id} percent={percent} time={time} trade={trade} />

      {index !== data.length - 1 && <div className={styles.wrapper__divider} />}
    </Fragment>
  ))

  return (
    <Modal
      title='DayInfo'
      className={styles.wrapper}
      contentClassName={styles.wrapper__content}
      titleClassName={styles.wrapper__title_container}
      open={open}
      onClose={onClose}
    >
      <p className={styles.wrapper__date}>{date ? formatDateToText(format(new Date(date), 'MM/dd/yyyy')) : ''}</p>

      <div className={styles.wrapper__date__container}>{renderItems}</div>
    </Modal>
  )
}

export default DayInfoModal

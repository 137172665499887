import { type FC, Fragment, useEffect } from 'react'

import { gatCoins } from 'store/global/actions'
import { RoutesWrapper } from 'libraries/router'
import { useAppDispatch } from 'libraries/redux'
import { Header, NavBar, Sidebar, ModalSystem } from 'components'
import { useBirthDayIsRequiredCase, useModalsSystemState, useResponsive } from 'hooks'

import styles from './PageLayout.module.scss'

const PageLayout: FC = () => {
  const dispatch = useAppDispatch()
  const { isLaptop } = useResponsive()
  const { isUserBirthday } = useBirthDayIsRequiredCase()

  const modalState = useModalsSystemState()

  useEffect(() => {
    dispatch(gatCoins())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <main className={styles.wrapper}>
        <Header modalState={modalState} />

        <section className={styles.wrapper__content}>
          <Sidebar modalState={modalState} />

          <RoutesWrapper />
        </section>

        {isLaptop && <NavBar />}
      </main>

      <ModalSystem modalState={modalState} isUserBirthday={isUserBirthday} />
    </Fragment>
  )
}

export default PageLayout

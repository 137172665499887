import { createAsyncThunk } from '@reduxjs/toolkit'

import axiosInstance from 'libraries/axios'
import { coinNames } from 'utils'
import { ToastVersions, showNotification } from 'libraries/toastify'

export const gatCoins = createAsyncThunk('coins/get', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('portfolio/coins')

    const filteredCoinsList = data.filter((item: any) => coinNames.includes(item.coinId))

    return filteredCoinsList
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

import { Link } from 'react-router-dom'

import { Button } from 'components'
import { EButtonVariants, ESizes } from 'types'
import { ZsChatIcon, UnlockIcon, channelBannerImage } from 'assets'

import styles from './Quotes.module.scss'

const chatsList = [
  {
    id: 1,
    icon: ZsChatIcon,
    text: 'ZS',

    // link: 'https://t.me/+fD5hZAwjj004ODEy',
  },
  {
    id: 2,
    icon: ZsChatIcon,
    text: 'ZS chat',
  },

  // {
  //   id: 3,
  //   icon: AirdropIcon,
  //   text: 'Airdrop',

  //   // link: 'http://t.me/Ars_channels_bot',
  // },
  // {
  //   id: 4,
  //   icon: DefiChatIcon,
  //   text: 'Defi',

  //   // link: 'http://t.me/Ars_channels_bot',
  // },
  // {
  //   id: 5,
  //   icon: NewsChatIcon,
  //   text: 'News',

  //   // link: 'http://t.me/Ars_channels_bot',
  // },
  // {
  //   id: 6,
  //   icon: AcademyChatIcon,
  //   text: 'ZC Academy',
  // },
]

const Quotes = () => {
  const renderChatsList = chatsList.map(({ id, icon: Icon, text }) => (
    <div key={id} className={styles.wrapper__info_chat_item}>
      <Icon />
      <p>{text}</p>
    </div>
  ))

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__banner}>
        <img src={channelBannerImage} alt='banner' />
      </div>
      <div className={styles.wrapper__info}>
        <p className={styles.wrapper__info_title}>Закрытое сообщество Присоединиться</p>
        <div className={styles.wrapper__info_chat}>{renderChatsList}</div>
        <div className={styles.wrapper__info_control}>
          {/* <Link size={ESizes.Large} navigateTo={'https://amatuni.gitbook.io/final-of-academy-russian/'} outsideNavigate>
            DEMO version
          </Link> */}

          <Link to={'https://t.me/Ars_channels_bot'} className={styles.wrapper__button}>
            <Button LeftIcon={UnlockIcon} size={ESizes.Medium} variant={EButtonVariants.Primary}>
              Unlock
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Quotes

import type { FC } from 'react'

import { TimeIcon } from 'assets'
import { TradingBadge, Text } from 'components'

import type { TPortfolioDatesItem } from './types'
import styles from './PortfolioDates.module.scss'

const PortfolioDatesItem: FC<TPortfolioDatesItem> = ({ time, percent, trade }) => (
  <div className={styles.wrapper__container}>
    <div className={styles.wrapper__container__time}>
      <TimeIcon />
      <p>
        <Text text='at' emptyTag /> {time}
      </p>
    </div>

    <div className={styles.wrapper__container__percent}>
      <TradingBadge color={trade} variant='badge' />
      <p>{percent}%</p>
    </div>
  </div>
)

export default PortfolioDatesItem

import { createSlice } from '@reduxjs/toolkit'

import {
  signIn,
  signUp,
  verifyEmail,
  resetPassword,
  forgotPassword,
  clearGoogleData,
  clearSignInData,
  signInWithGoogle,
  resendVerification,
  twoFactorQRGenerate,
  twoFactorAuthToggle,
  twoFactorAuthenticate,
  clearTwoFactorAuthToggle,
  clearTwoFactorAuthenticate,
} from './actions'

import { IAuthInitialState } from './types'

export const initialState: IAuthInitialState = {
  provider: {
    loading: false,
    error: null,
  },
  signIn: {
    data: null,
    error: null,
    loading: false,
  },
  signUp: {
    loading: false,
    error: null,
    data: null,
  },
  forgotPassword: {
    loading: false,
    error: null,
    data: null,
  },
  resetPassword: {
    loading: false,
    error: null,
  },
  verifyEmail: {
    loading: false,
    error: null,
    data: null,
  },
  resendVerification: {
    loading: false,
    error: null,
  },
  signInWithGoogle: {
    loading: false,
    error: null,
    data: null,
  },
  twoFactorVerify: {
    loading: false,
    error: null,
    data: null,
  },
  twoFactorAuthToggle: {
    loading: false,
    error: null,
    data: null,
  },
  twoFactorAuthenticate: {
    loading: false,
    error: null,
    data: null,
  },
  clearTwoFactorAuthenticate: {
    loading: false,
    error: null,
    data: null,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProvider: (state, action) => {
      state.provider = action.payload
    },
    setProviderInLoad: (state, action) => {
      state.provider = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(signIn.pending, state => {
      state.signIn.loading = true
      state.signIn.error = null
    })

    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.signIn.loading = false
      state.signIn.error = null
      state.signIn.data = payload
    })

    builder.addCase(clearSignInData.fulfilled, state => {
      state.signIn.loading = false
      state.signIn.error = null
      state.signIn.data = null
    })

    builder.addCase(signIn.rejected, (state, action) => {
      state.signIn.loading = false
      state.signIn.error = action.payload as null
    })

    builder.addCase(signUp.pending, state => {
      state.signUp.error = null
      state.signUp.loading = true
    })

    builder.addCase(signUp.fulfilled, (state, action) => {
      state.signUp.error = null
      state.signUp.loading = false
      state.signUp.data = action.payload
    })

    builder.addCase(signUp.rejected, (state, action) => {
      state.signUp.error = action.payload as null
      state.signUp.loading = false
    })

    builder.addCase(forgotPassword.pending, state => {
      state.forgotPassword.error = null
      state.forgotPassword.loading = true
    })

    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPassword.error = null
      state.forgotPassword.loading = false
      state.forgotPassword.data = action.payload
    })

    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPassword.error = action.payload as null
      state.forgotPassword.loading = false
    })

    builder.addCase(verifyEmail.pending, state => {
      state.verifyEmail.loading = true
      state.verifyEmail.error = null
    })

    builder.addCase(verifyEmail.fulfilled, state => {
      state.verifyEmail.loading = false
      state.verifyEmail.error = null
      state.verifyEmail.data = { verified: true }
    })

    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.verifyEmail.loading = false
      state.verifyEmail.error = action.payload as null
    })

    builder.addCase(resendVerification.pending, state => {
      state.resendVerification.loading = true
      state.resendVerification.error = null
    })

    builder.addCase(resendVerification.fulfilled, state => {
      state.resendVerification.loading = false
      state.resendVerification.error = null
    })

    builder.addCase(resendVerification.rejected, (state, action) => {
      state.resendVerification.loading = false
      state.resendVerification.error = action.payload as null
    })

    builder.addCase(resetPassword.pending, state => {
      state.resetPassword.loading = true
      state.resetPassword.error = null
    })

    builder.addCase(resetPassword.fulfilled, state => {
      state.resetPassword.loading = false
      state.resetPassword.error = null
    })

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPassword.loading = false
      state.resetPassword.error = action.payload as null
    })

    builder.addCase(signInWithGoogle.pending, state => {
      state.signInWithGoogle.loading = true
      state.signInWithGoogle.error = null
    })
    builder.addCase(signInWithGoogle.fulfilled, (state, { payload }) => {
      state.signInWithGoogle.error = null
      state.signInWithGoogle.loading = false
      state.signInWithGoogle.data = payload
    })
    builder
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.signInWithGoogle.loading = false
        state.signInWithGoogle.error = action.payload as null
      })

      .addCase(clearGoogleData.fulfilled, state => {
        state.signInWithGoogle.loading = false
        state.signInWithGoogle.error = null
        state.signInWithGoogle.data = null
      })

    // {
    builder.addCase(twoFactorQRGenerate.pending, state => {
      state.twoFactorVerify.loading = true
      state.twoFactorVerify.error = null
    })
    builder.addCase(twoFactorQRGenerate.fulfilled, (state, action) => {
      state.twoFactorVerify.loading = false
      state.twoFactorVerify.data = action.payload
    })
    builder.addCase(twoFactorQRGenerate.rejected, (state, action) => {
      state.twoFactorVerify.loading = false
      state.twoFactorVerify.error = action.payload as null
    })

    // }, {

    builder.addCase(twoFactorAuthToggle.pending, state => {
      state.twoFactorAuthToggle.loading = true
      state.twoFactorAuthToggle.error = null
    })
    builder.addCase(twoFactorAuthToggle.fulfilled, (state, { payload }) => {
      state.twoFactorAuthToggle.loading = false
      state.twoFactorAuthToggle.data = payload
    })
    builder.addCase(twoFactorAuthToggle.rejected, (state, { payload }) => {
      state.twoFactorAuthToggle.loading = false
      state.twoFactorAuthToggle.error = payload as null
    })

    builder.addCase(clearTwoFactorAuthToggle.fulfilled, state => {
      state.twoFactorAuthToggle.loading = false
      state.twoFactorAuthToggle.error = null
      state.twoFactorAuthToggle.data = null
    })

    // }, {

    builder.addCase(twoFactorAuthenticate.pending, state => {
      state.twoFactorAuthenticate.loading = true
      state.twoFactorAuthenticate.error = null
    })
    builder.addCase(twoFactorAuthenticate.fulfilled, (state, { payload }) => {
      state.twoFactorAuthenticate.loading = false
      state.twoFactorAuthenticate.data = payload
    })
    builder.addCase(twoFactorAuthenticate.rejected, (state, { payload }) => {
      state.twoFactorAuthenticate.loading = false
      state.twoFactorAuthenticate.error = payload as null
    })
    builder.addCase(clearTwoFactorAuthenticate.fulfilled, state => {
      state.twoFactorAuthenticate.loading = false
      state.twoFactorAuthenticate.data = null
      state.twoFactorAuthenticate.error = null
    })

    // }
  },
})

export const { name, actions } = authSlice
export const { setProvider, setProviderInLoad } = actions

const authReducer = authSlice.reducer

export default authReducer

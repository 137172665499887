import type { FC } from 'react'
import { useMemo } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import type { ISocialLinksData } from './types'
import styles from './SocialLinks.module.scss'

const SocialLinks: FC<ISocialLinksData> = ({ links, className = '', containerClassName = '' }) => {
  const socialLinks = useMemo(
    () =>
      links.map(({ Icon, navigate }, idx) => (
        <Link
          target='_blank'
          aria-label='social-media-ref'
          to={navigate}
          key={idx}
          className={className}
          data-testid={`social-link-${idx}`}
        >
          <Icon />
        </Link>
      )),
    [className, links]
  )

  return (
    <div
      data-testid='social-links-wrapper'
      className={classNames(styles.wrapper, {
        [containerClassName]: containerClassName,
      })}
    >
      {socialLinks}
    </div>
  )
}

export default SocialLinks

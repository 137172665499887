import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'libraries/redux'
import { clearProfileData } from 'store/user/actions'
import { getCookie, removeCookie } from 'libraries/cookie'
import { ToastVersions, showNotification } from 'libraries/toastify'
import { clearGoogleData, clearSignInData } from 'store/auth/actions'

const useAuthentication = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const token = getCookie('token')

  const userCookie = getCookie('user') ? JSON.parse(String(getCookie('user')) ?? '') : null

  const isAuthenticated = Boolean(!!token && !isEmpty(userCookie))

  const isGoogleAuth = getCookie('is_google_auth') || false

  const handleLogout = useCallback(() => {
    showNotification(ToastVersions.info, 'LogOut')

    removeCookie('user')
    removeCookie('token')
    removeCookie('rememberMe')
    removeCookie('refreshToken')

    dispatch(clearSignInData())
    dispatch(clearProfileData())

    if (isGoogleAuth) {
      dispatch(clearGoogleData())
      removeCookie('is_google_auth')
      navigate('/')
    } else {
      navigate('/')
    }
  }, [dispatch, isGoogleAuth, navigate])

  return {
    token,
    userCookie,
    handleLogout,
    isGoogleAuth,
    isAuthenticated,
  }
}

export default useAuthentication

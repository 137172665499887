import { type FC } from 'react'
import { NavLink } from 'react-router-dom'

import { useResponsive } from 'hooks'
import { setSidebarOpen } from 'store/global'
import { ESizes, EButtonVariants } from 'types'
import { useAppDispatch } from 'libraries/redux'
import { ERoutePaths } from 'libraries/router/types'
import { AmatuniPrimaryIcon, MenuIcon, AmatuniTextIcon } from 'assets'
import { Button, SettingsDropdown } from 'components'

import type { THeaderProps } from './types'
import styles from './Header.module.scss'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Header: FC<THeaderProps> = ({ modalState }) => {
  const dispatch = useAppDispatch()
  const { isLaptop, isWide } = useResponsive()

  const sideBarToggler = () => {
    dispatch(setSidebarOpen())
  }

  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__row}>
        {/* {!isLaptop && <ModeSwitch />} */}

        <SettingsDropdown />
      </div>

      <NavLink aria-label='Navigate To AMATUNI Home' to={ERoutePaths.Home} className={styles.wrapper__center}>
        <AmatuniPrimaryIcon className={styles.wrapper__center__icon} />

        {!isWide && <AmatuniTextIcon className={styles.wrapper__center__text} />}
      </NavLink>

      {/* @TODO add in future */}
      <div className={styles.wrapper_right}>
        {/* @TODO-_- Removed UI auth system */}

        {/* {!isLaptop && (
          <div className={styles.wrapper__row}>
            <LangDropdown />

            <AuthComponent modalState={modalState} />
          </div>
        )} */}

        <div className={styles.wrapper__mobile_buttons}>
          {isLaptop && (
            <Button
              size={ESizes.Large}
              LeftIcon={MenuIcon}
              onClick={sideBarToggler}
              ariaLabel='Sidebar Toggler'
              variant={EButtonVariants.Secondary}
              className={styles.wrapper__mobile_nav_button}
            />
          )}
        </div>
      </div>
    </header>
  )
}

export default Header

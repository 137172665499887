import { useEffect, useMemo, useRef, useState, type FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import classNames from 'classnames'

import { clearVideos } from 'store/content'
import { useButtonClickScroll } from 'hooks'
import { Button, Tabs, Text } from 'components'
import { EButtonVariants, ESizes } from 'types'
import { getVideos } from 'store/content/actions'
import { getIdByText, getTextById } from 'utils'
import type { THome } from 'components/molecules/types'
import type { TTab } from 'components/atom/Tabs/types'
import { ArrowLeftIcon, ArrowRightIcon } from 'assets'
import { ContentSelectors } from 'store/content/selectors'
import { useAppDispatch, useAppSelector } from 'libraries/redux'

import VideoCards from './VideoCards'
import { sortPresets } from './utils'
import styles from './VideoMaterials.module.scss'

const ITEM_WIDTH = 274

const VideoMaterials: FC<THome> = ({ className }) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null)

  const [activePage, setActivePage] = useState<number>(1)

  const { data, loading } = useAppSelector(ContentSelectors.getVideos)
  const tab = useMemo(() => searchParams.get('tab') || '', [searchParams])

  const {
    onContentScroll,
    handleNextButtonClick,
    handlePrevButtonClick,
    isPrevButtonsDisabled,
    isNextButtonsDisabled,
  } = useButtonClickScroll(scrollableContainerRef, ITEM_WIDTH, loading, tab)

  const renderCategoryTabs: TTab[] = ['Popular', 'Latest', 'Oldest'].map((text, id) => {
    return {
      id,
      text,
      ContentComponents: (
        <VideoCards
          setActivePage={setActivePage}
          hasMore={Boolean(data?.hasNextPage)}
          data={data?.items}
          loading={loading}
          onScroll={onContentScroll}
          ref={scrollableContainerRef}
        />
      ),
    }
  })

  const [activeTab, setActiveTab] = useState<number>(getIdByText(renderCategoryTabs, tab))

  useEffect(() => {
    if (getIdByText(renderCategoryTabs, tab) !== activeTab) {
      setSearchParams(prev => {
        prev.set('tab', `${getTextById(renderCategoryTabs, activeTab)}`)

        return prev
      })
    }
    setActivePage(1)
    dispatch(clearVideos())
    dispatch(getVideos({ ...sortPresets[activeTab], page: 1 }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    if (activePage > 1) {
      dispatch(getVideos({ ...sortPresets[activeTab], page: activePage }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage])

  useEffect(() => {
    if (getIdByText(renderCategoryTabs, tab) !== activeTab) {
      setActiveTab(getIdByText(renderCategoryTabs, tab))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.wrapper__header}>
        <Text text='VideoMaterials' tagName='h4' className={styles.wrapper__header__text} />

        <div className={styles.wrapper__header__container}>
          <Button
            size={ESizes.Small}
            LeftIcon={ArrowLeftIcon}
            disabled={isPrevButtonsDisabled}
            onClick={handlePrevButtonClick}
            variant={EButtonVariants.Secondary}
            className={styles.wrapper__header__button}
          />
          <Button
            size={ESizes.Small}
            LeftIcon={ArrowRightIcon}
            onClick={handleNextButtonClick}
            disabled={isNextButtonsDisabled}
            variant={EButtonVariants.Secondary}
            className={styles.wrapper__header__button}
          />
        </div>
      </div>

      <Tabs active={activeTab} setActive={setActiveTab} tabs={renderCategoryTabs} />
    </div>
  )
}

export default VideoMaterials

import { createSlice } from '@reduxjs/toolkit'

import { TGlobal } from './types'
import { gatCoins } from './actions'

const initialState: TGlobal = {
  authModalType: '',
  currentFaculty: '',
  isSideBarOpen: false,
  isLoginModalOpen: false,
  isRegistrationModalOpen: false,
  coins: {
    data: [],
    loading: false,
    error: null,
  },
}

const GlobalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setSidebarOpen: state => ({
      ...state,
      isSideBarOpen: true,
    }),
    setSidebarClosed: state => ({
      ...state,
      isSideBarOpen: false,
    }),
    toggleSidebar: state => ({
      ...state,
      isSideBarOpen: !state.isSideBarOpen,
    }),
    openLoginModal: state => ({
      ...state,
      isLoginModalOpen: true,
    }),
    openRegistrationModal: state => ({
      ...state,
      isRegistrationModalOpen: true,
    }),
    closeLoginModal: state => ({
      ...state,
      isLoginModalOpen: false,
    }),
    closeRegistrationModal: state => ({
      ...state,
      isRegistrationModalOpen: false,
    }),
    setCurrentFaculty: (state, action) => ({
      ...state,
      currentFaculty: action.payload,
    }),
    setAuthModalType: (state, action) => ({
      ...state,
      authModalType: action.payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(gatCoins.pending, state => {
      state.coins.loading = true
      state.coins.error = null
    })

    builder.addCase(gatCoins.fulfilled, (state, { payload }) => {
      state.coins.loading = false
      state.coins.error = null
      state.coins.data = payload
    })

    builder.addCase(gatCoins.rejected, (state, action) => {
      state.coins.loading = false
      state.coins.error = action.payload as null
    })
  },
})

export const {
  toggleSidebar,
  closeLoginModal,
  closeRegistrationModal,
  setSidebarOpen,
  setSidebarClosed,
  setAuthModalType,
} = GlobalSlice.actions

const globalSliceReducer = GlobalSlice.reducer

export default globalSliceReducer

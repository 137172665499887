import axios, { AxiosRequestConfig } from 'axios'

import { COOKIE_EXPIRATION_TIME } from 'utils'
import { getCookie, removeCookie, setCookie } from 'libraries/cookie'

const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_URL,
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const selectedLanguage = localStorage.getItem('i18nextLng') || 'en'

    const token = getCookie('token')

    config.headers = {
      Authorization: `Bearer ${token}`,
      'x-custom-lang': selectedLanguage,
    }

    return config
  },

  error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  config => Promise.resolve(config),
  async error => {
    const originalRequest = error.config

    const refreshToken = getCookie('refreshToken') as string

    if (error.response.status === 401 && Boolean(refreshToken)) {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-token`, {
          refreshToken,
        })
        setCookie('token', data?.data?.accessToken, COOKIE_EXPIRATION_TIME)

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data?.data?.accessToken}`

        return axiosInstance(originalRequest)
      } catch (err: any) {
        removeCookie('user')
        removeCookie('token')
        removeCookie('refreshToken')
        removeCookie('is_google_auth')

        location.replace('/')

        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }
)

export default axiosInstance

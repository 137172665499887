import { createAsyncThunk } from '@reduxjs/toolkit'

import axiosInstance from 'libraries/axios'
import { showNotification, ToastVersions } from 'libraries/toastify'

export const gatNews = createAsyncThunk('gatNews', async ({ id }: { id: number | string }, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get(`/news?coinId=${id}`)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const gatNewsById = createAsyncThunk('gatNewsById', async ({ id }: { id: number }, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/news')
    const activeNews = data.data.find((news: any) => news.id === id)

    return activeNews
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

// blog

export const getBlog = createAsyncThunk('getBlog', async ({ id }: { id: number | string }, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get(`/blog?coinId=${id}`)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const gatBlogById = createAsyncThunk('gatBlogById', async ({ id }: { id: number }, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/blog')
    const activeNews = data.data.find((news: any) => news.id === id)

    return activeNews
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})
